<template>
  <div id="notfound" class="ml-5">
    <h1>Pagina niet gevonden.</h1>
    <h3>Deze pagina is niet bekend, ga terug naar <a href="/">hoofdpagina.</a></h3>
  </div>
</template>

<script>
export default {

}
</script>

<style>
#notfound{
    color: black !important;
}
</style>